// Thanks https://spectrum.adobe.com/page/icons/

const css = "fill-current size-[1.5rem] sm:size-[1.75rem] lg:size-[2rem] xl:size-[2.25rem] 2xl:size-[2.5rem]";

export const ABC = (
  <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18">
    <title>Alphabetic</title>
    <path
      class="fill"
      d="M3.0805,8.3675,2.31,10.878c-.028.091-.0705.122-.154.122H.756c-.0845,0-.1125-.0455-.1-.15L3.5425,1.9285A2.51436,2.51436,0,0,0,3.669,1.107c0-.0615.028-.107.084-.107H5.7c.0705,0,.084.015.1.0915L9.0355,10.863c.0145.0915,0,.137-.084.137H7.381a.14051.14051,0,0,1-.1405-.0915L6.428,8.3675Zm2.9-1.6595c-.294-1.005-.9525-3.12-1.233-4.1855h-.014c-.224,1.02-.785,2.8-1.2045,4.1855Z"
    />
    <path
      class="fill"
      d="M9.613,17c-.0565,0-.1125-.015-.1125-.122v-1.02a.346.346,0,0,1,.042-.1825l4.861-7.032H9.711c-.0705,0-.1125-.0145-.1-.106l.21-1.4155C9.835,7.031,9.877,7,9.9465,7H16.463c.069,0,.084.031.084.0915v1.096a.3265.3265,0,0,1-.0705.213L11.7,15.3415h5.015c.069,0,.1.0455.069.137l-.2235,1.4c-.0135.091-.042.122-.126.122Z"
    />
  </svg>
);

export const RANKING = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Ranking</title>
    <path
      class="fill"
      d="M9,2a.497.497,0,0,0-.3735.168l-5.5,5A.48949.48949,0,0,0,3,7.497.5.5,0,0,0,3.497,8H14.5a.5.5,0,0,0,.5-.5V7.497a.48949.48949,0,0,0-.1275-.329l-5.5-5A.5.5,0,0,0,9,2Z"
    />
    <path
      class="fill"
      d="M9,16a.5.5,0,0,0,.3735-.168l5.5-5A.48949.48949,0,0,0,15,10.503.5.5,0,0,0,14.503,10H3.5a.5.5,0,0,0-.5.5v.003a.48949.48949,0,0,0,.1275.329l5.5,5A.497.497,0,0,0,9,16Z"
    />
  </svg>
);

export const FULLCIRCLE = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Circle</title>
    <circle class="fill" cx="9" cy="9" r="8" />
  </svg>
);

export const SUN = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Sun</title>
    <path d="m9,5.05c2.18152,0,3.95,1.76848,3.95,3.95s-1.76848,3.95-3.95,3.95-3.95-1.76848-3.95-3.95,1.76848-3.95,3.95-3.95Zm-.9-4.8v2.5c0,.13807.11193.25.25.25h1.3c.13807,0,.25-.11193.25-.25V.25c0-.13807-.11193-.25-.25-.25h-1.3c-.13807,0-.25.11193-.25.25Zm0,15v2.5c0,.13807.11193.25.25.25h1.3c.13807,0,.25-.11193.25-.25v-2.5c0-.13807-.11193-.25-.25-.25h-1.3c-.13807,0-.25.11193-.25.25ZM0,8.35v1.3c0,.13807.11193.25.25.25h2.5c.13807,0,.25-.11193.25-.25v-1.3c0-.13807-.11193-.25-.25-.25H.25c-.13807,0-.25.11193-.25.25Zm15,0v1.3c0,.13807.11193.25.25.25h2.5c.13807,0,.25-.11193.25-.25v-1.3c0-.13807-.11193-.25-.25-.25h-2.5c-.13807,0-.25.11193-.25.25Zm-2.01021-4.23913l.91924.91924c.09763.09763.25592.09763.35355,0l1.76777-1.76777c.09763-.09763.09763-.25592,0-.35355l-.91924-.91924c-.09763-.09763-.25592-.09763-.35355,0l-1.76777,1.76777c-.09763.09763-.09763.25592,0,.35355ZM1.98978,15.11121l.91924.91924c.09763.09763.25592.09763.35355,0l1.76777-1.76777c.09763-.09763.09763-.25592,0-.35355l-.91924-.91924c-.09763-.09763-.25592-.09763-.35355,0l-1.76777,1.76777c-.09763.09763-.09763.25592,0,.35355Zm-.01031-11.8587l1.76777,1.76777c.09763.09763.25592.09763.35355,0l.91924-.91924c.09763-.09763.09763-.25592,0-.35355l-1.76777-1.76777c-.09763-.09763-.25592-.09763-.35355,0l-.91924.91924c-.09763.09763-.09763.25592,0,.35355Zm10.9902,10.99001l1.76777,1.76777c.09763.09763.25592.09763.35355,0l.91924-.91924c.09763-.09763.09763-.25592,0-.35355l-1.76777-1.76777c-.09763-.09763-.25592-.09763-.35355,0l-.91924.91924c-.09763.09763-.09763.25592,0,.35355Z" />
  </svg>
);

export const MOON = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Moon</title>
    <path d="M9,1a8,8,0,1,0,8,8A8,8,0,0,0,9,1Zm.5,14.982c-.165.0115-.332.018-.5.018A7,7,0,0,1,9,2c.168,0,.335.0065.5.018A11,11,0,0,0,9.5,15.982Z" />
  </svg>
);

export const MENU = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Menu</title>
    <rect height="2" rx="0.5" width="14" x="2" y="8" />
    <rect height="2" rx="0.5" width="14" x="2" y="3" />
    <rect height="2" rx="0.5" width="14" x="2" y="13" />
  </svg>
);

export const CLOSE = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Close</title>
    <path d="M13.2425,3.343,9,7.586,4.7575,3.343a.5.5,0,0,0-.707,0L3.343,4.05a.5.5,0,0,0,0,.707L7.586,9,3.343,13.2425a.5.5,0,0,0,0,.707l.707.7075a.5.5,0,0,0,.707,0L9,10.414l4.2425,4.243a.5.5,0,0,0,.707,0l.7075-.707a.5.5,0,0,0,0-.707L10.414,9l4.243-4.2425a.5.5,0,0,0,0-.707L13.95,3.343a.5.5,0,0,0-.70711-.00039Z" />
  </svg>
);

export const FILTER = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Filter</title>
    <path d="M15.473,1H1.527a.5.5,0,0,0-.3935.8085L7,9.2945V16.95a.496.496,0,0,0,.84.412l1.9905-2.0765A.60949.60949,0,0,0,10,14.864V9.2945l5.8665-7.486A.5.5,0,0,0,15.473,1Z" />
  </svg>
);

export const FILTER_EDIT = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Filter Edit</title>
    <path d="M17.8925,10.8605,16.14,9.1075A.36952.36952,0,0,0,15.8765,9H15.865a.41651.41651,0,0,0-.282.1235l-5.411,5.411a.304.304,0,0,0-.0765.128l-1.0135,3c-.0345.1145.1395.2585.238.2585a.15858.15858,0,0,0,.0185,0c.084-.0195,2.5615-.882,3-1.014a.3.3,0,0,0,.126-.0755l5.412-5.4145A.41749.41749,0,0,0,18,11.15.37152.37152,0,0,0,17.8925,10.8605Zm-5.8,5.4815c-.657.1975-1.65.6145-2.215.784l.78-2.2155Z" />
    <path d="M15.473,1H1.527a.5.5,0,0,0-.3935.8085L7,9.2945V15a.496.496,0,0,0,.84.4125L9.83,13.336a.61.61,0,0,0,.17-.4225V9.2945l5.8665-7.486A.5.5,0,0,0,15.473,1Z" />
  </svg>
);

export const FILTER_CLOSE = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Filter Close</title>
    <path d="M13.5,9.05a4.45,4.45,0,1,0,4.45,4.45A4.45,4.45,0,0,0,13.5,9.05Zm2.913,6.1335a.25.25,0,0,1,0,.3535l-.876.876a.25.25,0,0,1-.3535,0L13.5,14.7295,11.8165,16.413a.25.25,0,0,1-.3535,0l-.876-.876a.25.25,0,0,1,0-.3535L12.2705,13.5,10.587,11.8165a.25.25,0,0,1,0-.3535l.876-.876a.25.25,0,0,1,.3535,0L13.5,12.2705l1.6835-1.6835a.25.25,0,0,1,.3535,0l.876.876a.25.25,0,0,1,0,.3535L14.7295,13.5Z" />
    <path d="M7.4,13.5A6.573,6.573,0,0,1,9,9.2945c1.0415-1.347,5.8665-7.486,5.8665-7.486A.5.5,0,0,0,14.473,1H.527a.5.5,0,0,0-.3935.8085L6,9.2945V16.95a.496.496,0,0,0,.84.4125L8.009,16.143A6.06548,6.06548,0,0,1,7.4,13.5Z" />
  </svg>
);

export const BOOLEAN = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Boolean</title>
    <path d="M12,4.25a4.75,4.75,0,0,1,0,9.5H6a4.75,4.75,0,0,1,0-9.5ZM12,3H6A6,6,0,0,0,6,15h6A6,6,0,0,0,12,3Zm0,3A3,3,0,1,1,9,9,3,3,0,0,1,12,6Z" />
  </svg>
);

export const DELETE = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Delete</title>
    <path d="M15.75,3H12V2a1,1,0,0,0-1-1H6A1,1,0,0,0,5,2V3H1.25A.25.25,0,0,0,1,3.25v.5A.25.25,0,0,0,1.25,4h1L3.4565,16.55a.5.5,0,0,0,.5.45H13.046a.5.5,0,0,0,.5-.45L14.75,4h1A.25.25,0,0,0,16,3.75v-.5A.25.25,0,0,0,15.75,3ZM5.5325,14.5a.5.5,0,0,1-.53245-.46529L5,14.034l-.5355-8a.50112.50112,0,0,1,1-.067l.5355,8a.5.5,0,0,1-.46486.53283ZM9,14a.5.5,0,0,1-1,0V6A.5.5,0,0,1,9,6ZM11,3H6V2h5Zm1,11.034a.50112.50112,0,0,1-1-.067l.5355-8a.50112.50112,0,1,1,1,.067Z" />
  </svg>
);

export const DESELECT = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Deselect</title>
    <path d="m2,9h1v3h-1v-3Zm1,6v-1h-1v1.5555c0,.24549.19901.4445.4445.4445h1.5555v-1h-1Zm3,1h3v-1h-3v1Zm9-7h1v-3h-1v3Zm.5555-7h-1.5555v1h1v1h1v-1.5555c0-.24549-.19901-.4445-.4445-.4445Zm-6.5555,1h3v-1h-3v1ZM1.10295,1.97065l14.92632,14.92632c.15973.15973.41869.15972.57841,0l.28956-.28956c.15972-.15972.15973-.41869,0-.57841L1.97092,1.10268c-.15972-.15972-.41869-.15972-.57841,0l-.28956.28956c-.15972.15972-.15972.41869,0,.57841Zm14.89705,11.74835v-2.719h-1v1.719l1,1Zm-3.282,1.281h-1.718v1h2.718l-1-1ZM2,4.281v2.719h1v-1.719l-1-1Zm3.281-1.281h1.719v-1h-2.719l1,1Z" />
  </svg>
);

export const LINKOUT = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Link Out</title>
    <path d="M11.77,1.4265l1.6945,1.695-4.773,4.773a.25.25,0,0,0,0,.3535L9.75,9.3085a.25.25,0,0,0,.3535,0l4.773-4.773L16.571,6.23A.25.25,0,0,0,17,6.0535V1H11.9465a.25.25,0,0,0-.1765.4265Z" />
  </svg>
);

export const SORT_DOWN = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Sort Down</title>
    <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
    <rect class="fill" height="2" rx="0.5" width="6" x="1" y="12" />
    <rect height="2" rx="0.5" width="8" x="1" y="8" />
    <rect height="2" rx="0.5" width="10" x="1" y="4" />
    <path d="M16,12H14.9965V4.5a.494.494,0,0,0-.488-.5L14.503,4h-.496a.5.5,0,0,0-.5.5L13.5035,12H12.5a.25.25,0,0,0-.25.25.245.245,0,0,0,.0735.175l1.7685,2.0165a.25.25,0,0,0,.316,0l1.7685-2.0165a.245.245,0,0,0,.0735-.175A.25.25,0,0,0,16,12Z" />
  </svg>
);

export const SORT_UP = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" class={css}>
    <title>Sort Up</title>
    <rect height="2" rx="0.5" width="6" x="1" y="4" />
    <rect height="2" rx="0.5" width="8" x="1" y="8" />
    <rect height="2" rx="0.5" width="10" x="1" y="12" />
    <path d="M15.99951,6H14.99634v7.5a.49378.49378,0,0,1-.49317.5h-.49633a.5.5,0,0,1-.5-.49951L13.50366,6H12.50049A.24984.24984,0,0,1,12.25,5.74823a.24439.24439,0,0,1,.07373-.175L14.0918,3.5564a.25007.25007,0,0,1,.3164,0l1.76807,2.01684a.24439.24439,0,0,1,.07373.175A.24984.24984,0,0,1,15.99951,6Z" />
  </svg>
);
